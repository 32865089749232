import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Typography, Tabs, Tab, Box, IconButton } from '@mui/material';
import Layout from './Layout';
import OverviewTab from './OverviewTab';
import TextTab from './TextTab';
import VotesTab from './VotesTab';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark'; 

function BillPage() {
  const { id } = useParams();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [billData, setBillData] = useState(null);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const userId = localStorage.getItem('user_id');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBookmarkClick = async () => {
    const action = isBookmarked ? 'delete' : 'add';
    const response = await fetch(`${process.env.REACT_APP_API_URL}/save-user-bill/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
      body: JSON.stringify({ bill_id: id, user_id: userId, action: action }), // Include user_id and action in the request body
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    setIsBookmarked(!isBookmarked); // Toggle the bookmark state
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');
    if (tab) {
      setValue(parseInt(tab, 10));
    }

    fetch(`${process.env.REACT_APP_API_URL}/bills/info/${id}/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setBillData(data);
        setIsBookmarked(data.bill.tracking); 
      })
      .catch(error => console.error('Error fetching bill data:', error));
  }, [id, location.search]);

  const vote = billData?.bill?.vote_type ?? null;
  const bill = billData?.bill ?? null;
  return (
    <Layout>
      <Box sx={{ width: '100%' }}>
        {billData && (
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Lato, sans-serif',
              fontSize: 24,
              lineHeight: 1.5,
              fontWeight: 'bold',
            }}
          >
            <IconButton onClick={handleBookmarkClick} style={{ verticalAlign: 'middle', marginRight: 8 }}>
              {isBookmarked ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            </IconButton>

            {billData['bill']['state']} {billData['bill']['bill_number']} - {billData['bill']['title']}
          </Typography>
        )}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="bill tabs">
            <Tab label="Overview" />
            <Tab label="Text" />
            <Tab label="Votes" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <OverviewTab id={id} vote={vote} bill={bill}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TextTab id={id} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <VotesTab id={id} />
        </TabPanel>
      </Box>
    </Layout>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ maxHeight: '80vh', overflowY: 'auto' }} // Apply scrollable styles here
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default BillPage;