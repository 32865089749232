import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { Box, TextField, Button, Typography, Alert, FormControl, MenuItem, Select, InputLabel } from '@mui/material';

function ProfilePage() {
  const [searchQuery, setSearchQuery] = useState('');
  const userId = localStorage.getItem('user_id');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [availableStates, setAvailableStates] = useState([]);
  const [currentState, setCurrentState] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setEmail(data.email);
        setOrganizationName(data.organization_name);
        setCurrentState(data.current_state);

        const fetchOrganizationStates = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/organization-state?organization_id=${data.organization}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
              },
            });

            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            const statesData = await response.json();
            setAvailableStates(statesData.map(state => state.state));
          } catch (error) {
            console.error('Error fetching organization states:', error);
          }
        };

        fetchOrganizationStates();
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password && password !== confirmPassword) {
      setError('Passwords do not match');
      setSuccess('');
      return;
    }

    if (password && password.length < 8) {
      setError('Password must be at least 8 characters long');
      setSuccess('');
      return;
    }

    const updateData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      current_state: currentState,
    };

    if (password) {
      updateData.password = password;
    }
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/update/${userId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Profile updated successfully:', result);
      setSuccess('Profile updated successfully');
      setError('');
      // Optionally, you can clear the form or show a success message
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Error updating profile');
      setSuccess('');
    }
  };

return (
  <Layout searchQuery={searchQuery}>
    <Box sx={{ width: '100%', maxWidth: '600px', padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Profile
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Organization (Not Editable)"
          value={organizationName}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Current State</InputLabel>
          <Select
            value={currentState}
            onChange={(e) => setCurrentState(e.target.value)}
            label="Current State"
          >
            {availableStates.map((state, index) => (
              <MenuItem key={index} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Password (Optional)"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Confirm Password (Optional)"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          margin="normal"
        />
        {error && (
          <Typography color="error" sx={{ marginTop: 1 }}>
            {error}
          </Typography>
        )}
        {success && (
          <Alert severity="success" sx={{ marginTop: 1 }}>
            {success}
          </Alert>
        )}
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginTop: 2 }}>
          Update Profile
        </Button>
      </form>
    </Box>
  </Layout>
);
}

export default ProfilePage;