import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { Box, TextField, Button, Typography, TableContainer, Paper, Table, TableCell, TableHead, TableBody, TableRow, TableSortLabel, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

function ScorecardPage() {
    const [searchQuery, setSearchQuery] = useState('');
    const userId = localStorage.getItem('user_id');
    const [data, setData] = useState([]);
    const [issues, setIssues] = useState([]);
    const [open, setOpen] = useState(false);
    const [categoryId, setCategoryId] = useState(0);

    useEffect(() => {
        const fetchIssues = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/issues-all`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                    }
                });
                const data = await response.json();
                setIssues(data);
            } catch (error) {
                console.error('Error fetching issues:', error);
            }
        };

        fetchIssues();
    }, []);

    const [selectedIssue, setSelectedIssue] = useState('');
    const [loading, setLoading] = useState(false);
    const [dialogLoading, setDialogLoading] = useState(false);
    const [support, setSupport] = useState([]);
    const [candidateName, setCandidateName] = useState('');

    const handleEmojiClick = async (candidateId, candidateName) => {
        setDialogLoading(true);
        setOpen(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/feeds/candidate/${candidateId}/${categoryId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                }
            });
            const data = await response.json();
            setSupport(data);
            setCandidateName(candidateName);
            setDialogLoading(false);
        } catch (error) {
            console.error('Error fetching candidate feed:', error);
        }
    };
    
    useEffect(() => {
        const fetchData = async () => {
            if (selectedIssue) {
                setLoading(true);

                const issue = issues.find(issue => issue.id == selectedIssue);
                if (issue) {
                    setCategoryId(issue.category_id);
                }
                
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/get-candidate-attribution/${userId}/${selectedIssue}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
                        }
                    });
                    const ret = await response.json();
                    setData(ret);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [selectedIssue]);

    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const sortedData = React.useMemo(() => {
        let sortableData = [...data];
        if (sortConfig.key) {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    return (
        <Layout searchQuery={searchQuery}>
            <Box sx={{ minWidth: 500, marginTop: 4, marginRight: 4, display: 'flex' }}>
                <TextField
                    select
                    label="Select Issue"
                    value={selectedIssue}
                    onChange={(event) => setSelectedIssue(event.target.value)}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                    sx={{ marginLeft: 1 }}
                >
                    <option value=""></option>
                    {issues.map((issue) => (
                        <option key={issue.id} value={issue.id}>
                            {issue.statement}
                        </option>
                    ))}
                </TextField>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
                    <CircularProgress />
                </Box>
            ) : sortedData.length > 0 ? (
                <Box sx={{ marginTop: 4, marginRight: 4 }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'position_name'}
                                            direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                            onClick={() => requestSort('position_name')}
                                        >
                                            Chamber
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'district'}
                                            direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                            onClick={() => requestSort('district')}
                                        >
                                            District
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'candidate_name'}
                                            direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                            onClick={() => requestSort('candidate_name')}
                                        >
                                            Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'party_name'}
                                            direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                            onClick={() => requestSort('party_name')}
                                        >
                                            Party
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'for_position'}
                                            direction={sortConfig.direction === 'asc' ? 'asc' : 'desc'}
                                            onClick={() => requestSort('for_position')}
                                        >
                                            Agreement
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.position_name}</TableCell>
                                        <TableCell>{item.district}</TableCell>
                                        <TableCell>{item.candidate_name}</TableCell>
                                        <TableCell>{item.party_name}</TableCell>
                                        <TableCell>
                                            {item.for_position == null ? (
                                                'N/A'
                                            ) : (
                                                <Button onClick={() => handleEmojiClick(item.id, item.candidate_name)}>{item.for_position ? '👍' : '👎'}</Button>
                                            )}
                                        </TableCell>                                    
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Dialog open={open} onClose={() => setOpen(false)}>
                        <DialogTitle>Things {candidateName} Said</DialogTitle>
                        <DialogContent>
                            {dialogLoading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <DialogContentText>
                                    {support.map((item, index) => (
                                        <Box sx={{ border: '1px solid', padding: 2, marginBottom: 2 }} key={index}>
                                            {item.value} <br /> 
                                            <b>Source:</b> <a href={item.source} target="_blank" rel="noopener noreferrer">{item.type}</a>
                                        </Box>
                                    ))}
                                </DialogContentText>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            ) : (
                <Typography variant="h6" sx={{ marginTop: 4 }}>
                    No Results Found
                </Typography>
            )}
        </Layout>
    );
}

export default ScorecardPage;