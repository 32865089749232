import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { Box, TextField, Button, IconButton, Typography, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AutorenewIcon from '@mui/icons-material/Autorenew';

function TagPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [tag, setTag] = useState('');
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem('user_id');

  useEffect(() => {
    // Fetch all tags on component mount
    const fetchTags = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag?user_id=${userId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setTags(result);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    fetchTags();
  }, [userId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true); // Set loading to true
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
      },
      body: JSON.stringify({
        user: userId,
        tag: tag,
      }),
      });

      if (!response.ok) {
      throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setTags([result, ...tags]); // Add the new tag to the front of the list
      setTag(''); // Clear the input field
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const handleDelete = async (tagId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag/${tagId}/?user_id=${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setTags(tags.filter(tag => tag.id !== tagId)); // Remove the deleted tag from the list
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const handleGenerate = async (tagId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user-tag/${tagId}/generate-subtags/?user_id=${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${process.env.REACT_APP_API_TOKEN}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setTags([...result, ...tags]); // Add the new subtags to the front of the list
    } catch (error) {
      console.error('Error generating subtags:', error);
    }
  };

  return (
    <Layout searchQuery={searchQuery}>
      <Box sx={{ height: '80vh', width: '80vw', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Tags
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
          <TextField
            fullWidth
            label="Tag"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            margin="normal"
            required
          />
          <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
            {loading ? 'Loading...' : 'Add Tag'}
          </Button>
        </form>
        <Box sx={{ marginTop: 4, width: '100%', maxWidth: '350px' }}>
          {tags.map((tag) => (
            <Box key={tag.id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 1, border: '1px solid #ccc', borderRadius: 1, marginBottom: 1 }}>
              <Typography>{tag.parent ? `${tag.parent} - ${tag.tag}` : tag.tag}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Click here to generate additional tags based on this one">
                  <IconButton onClick={() => handleGenerate(tag.id)} size="small">
                    <AutorenewIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Click here to delete this tag">
                  <IconButton onClick={() => handleDelete(tag.id)} size="small">
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Layout>
  );
}

export default TagPage;